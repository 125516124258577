import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import CustomerServiceMainPageViewModelType from './CustomerServiceMainPageViewModel.type';

type CustomerServiceMainPage = CustomerServiceMainPageViewModelType & PageType;

export default (currentPage: PageType): currentPage is CustomerServiceMainPage => {
  return (
    currentPage.componentName ===
    componentNames.Avensia_Common_Features_CustomerService_CustomerServiceMainPageViewModel
  );
};
