/**
 * @ComponentFor LinkIconTextBlockViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { currentUrl } from '@avensia/scope';
import connect from 'Shared/connect';
import Link from 'Shared/Link';
import { pixelsToUnit, thin } from 'Shared/Style';
import isCurrentPageCsm from './current-page-is-customer-service-main';
import { black } from 'Shared/Style/colors';
import LinkIconTextBlockViewModel from './LinkIconTextBlockViewModel.type';

type ConnectStateType = { isCustomerServiceMainPage: boolean };

type PropType = LinkIconTextBlockViewModel & ConnectStateType;

const activeBorderColor = '#A7A7A7';
const iconBackgroundColor = '#F1F1F1';
const activeIconBackgroundColor = '#CCE7EE';

const LinkIconTextBlock = (props: PropType) => {
  const activeLinkWrapper = {
    border: {
      xy: { color: activeBorderColor },
    },
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.17)',
  };
  const activeLinkToPage = !props.isCustomerServiceMainPage && currentUrl().pathname === props.url;
  return (
    <LinkBlockWrapper css={activeLinkToPage && activeLinkWrapper}>
      <LinkWrapper to={props.url} linkIdentifier={props.ticket}>
        <ImageWrapper css={activeLinkToPage && { backgroundColor: activeIconBackgroundColor }}>
          <img src={props.imageUrl} />
        </ImageWrapper>
        <Text>{props.text}</Text>
      </LinkWrapper>
    </LinkBlockWrapper>
  );
};

export default connect(
  (state): ConnectStateType => ({
    isCustomerServiceMainPage: isCurrentPageCsm(state.currentPage),
  }),
)(LinkIconTextBlock);

const LinkBlockWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: pixelsToUnit(112),
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: '#E9E9E9',
    },
  },
});
const LinkWrapper = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textDecoration: 'none',
  width: '100%',
  height: '100%',
});

const Text = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: black,
});

const ImageWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: pixelsToUnit(66),
  height: pixelsToUnit(66),
  backgroundColor: iconBackgroundColor,
  borderRadius: '50%',
  margin: {
    x: 'auto',
    y: 0,
  },
});
